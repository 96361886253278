import React, { useEffect, useState } from "react"

export default function() {
  return <div></div>
}
// import Videojs from "../components/singerunde/videojs.js"
// import styled from "styled-components"
// import { spacing } from "../constants/spacing"
// import ReactPlayer from "react-player"
// import Layout from "../components/Layout"
// import Voting from "../components/singerunde/Voting"
// import axios from "axios"
// import { DoubleView } from "../components/singerunde/styled"
// import { Button } from "@material-ui/core"
//
// const DefaultPage = styled.div`
//   padding: 0 1rem;
//
//   h3 {
//     font-weight: 300;
//     font-size: 1.4rem;
//   }
//
//   .video-container {
//     .video-js {
//       //  width: 720px;
//       //  height: 420px;
//     }
//   }
//
//   .react-player {
//     width: 100%;
//   }
// `
//
// const VideoWrapper = styled.div`
//   width: 100%;
//   height: 480px;
//
//   .video-js {
//     width: 100%;
//   }
//
//   .vjs-text-track-display {
//     display: none !important;
//   }
// `
//
// const VIDEO_URL = "https://conf.vcp-rps.de/stream/gab.m3u8"
// //  "http://demo.unified-streaming.com/video/tears-of-steel/tears-of-steel.ism/.m3u8"
//
// const videoJsOptions = {
//   autoplay: false,
//   playbackRates: [0.5, 1, 1.25, 1.5, 2],
//   width: 720,
//   height: 480,
//   controls: true,
//   // src: VIDEO_URL,
//   sources: [
//     {
//       src: VIDEO_URL,
//       type: "application/x-mpegURL",
//       selected: true,
//     },
//   ],
// }
//
// export default function Singerunde() {
//   const [isLive, setIsLive] = useState(false)
//   const [allowChat, setAllowChat] = useState(false)
//
//   useEffect(() => {
//     axios
//       .get(VIDEO_URL)
//       .then(r => {
//         setIsLive(true)
//       })
//       .catch(r => {
//         console.error(r)
//       })
//   })
//
//   return (
//     <Layout title="Gau Alt-Burgund | Singerunde">
//       <DefaultPage>
//         <h2>Singerunde</h2>
//         <p>
//           Hier seht ihr den Livstream der Gausingerunde, die am 12.03 um
//           19:30Uhr stattfindet, bei der ihr auch gerne mitsingen könnt. Darunter
//           befindet sich eine Liedwunschliste, bei der ihr auch Lieder selber
//           hinzufügen könnt, in dem ihr den Titel des Liedes (und wenn ihr es
//           wisst, dann auch die seitenzahl) eingebt und absendet. (Achtet bitte
//           auch darauf, dass sich keine Lieder doppeln) Unter anderem könnt ihr
//           mit einem Klick auf Lieder die ihr hören/singen wollt, dies kenntlich
//           machen. Achtung, man kann dies nicht zurücknehmen. Wenn jemand auch
//           noch Interesse hat etwas vorzusingen, kann er sich gerne bei uns
//           melden. Ansonsten wünschen wir euch viel Spaß bei der Singerrunde. Es
//           wäre gut wenn ihr uns, für die Zuschusslisten, euren ganzen Namen,
//           eure Postleitzahl, euren Wohnort und euer Geburtsjahr an
//           musisch@altburgund.de schickt. (Eure Daten werden vertraulich
//           behandelt, nur für die Zuschusslisten verwendet und anschließend
//           gelöscht) Miriam und Barbara
//         </p>
//         <DoubleView>
//           <div className={"video-container"}>
//             <h3>Live Stream</h3>
//
//             <VideoWrapper>
//               {isLive ? (
//                 <Videojs {...videoJsOptions} />
//               ) : (
//                 <p>Der Stream ist gerade offline.</p>
//               )}
//             </VideoWrapper>
//           </div>
//
//           <div className={"chat-container"}>
//             <h3>Chat</h3>
//
//             {allowChat ? (
//               <iframe
//                 src={"https://tlk.io/gabtratsch"}
//                 style={{
//                   height: "480px",
//                   width: "100%",
//                   borderStyle: "solid",
//                   borderWidth: "1px",
//                 }}
//                 sandbox=""
//               />
//             ) : (
//               <>
//                 <p>
//                   Mit dem Laden des Chats werden durch tlk.io Cookies gesetzt
//                   und Anfragen an externe Dienste (Google, Cloudflare) gesendet.
//                   Chat aktivieren?
//                 </p>
//                 <button onClick={() => setAllowChat(true)}>
//                   Chat erlauben
//                 </button>
//               </>
//             )}
//           </div>
//         </DoubleView>
//
//         <Voting />
//
//         <section className={"teilnahme"}>
//           <h3>Teilnahme</h3>
//           <p>
//             Wenn ihr an dieser Singerunde teilnehmt, könnt ihr uns hier eure
//             Kontaktdaten mitteilen.
//           </p>
//         </section>
//       </DefaultPage>
//     </Layout>
//   )
// }
